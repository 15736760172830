import * as React from 'react';

import TopMenu from './top-menu';
import Footer from './footer';

import * as classes from './secondary-page-layout.module.css';

type SecondaryPageLayoutProps = {
  children: React.ReactNode;
  contentClassName?: string;
  pageClassName?: string;
}

function SecondaryPageLayout(props: SecondaryPageLayoutProps) {
  const {
    children,
    contentClassName,
    pageClassName,
  } = props;

  return (
    <div className={classes.wrapper}>
      <TopMenu />
      <main className={`${classes.pageLayout} ${pageClassName || ''}`}>
        <div className={`${classes.content} ${contentClassName || ''}`}>
          {children}
        </div>
      </main>

      <Footer />
    </div>
  );
}

export default SecondaryPageLayout;
