import * as React from 'react';
import { graphql } from 'gatsby';

import SecondaryPageLayout from '../components/secondary-page-layout';
import Meta from '../components/meta';
import { PrivacyPolicyPageTitle } from '../localisation';
import { Url } from '../url';

type QueryData = {
  iubendaDocument: {
    privacyPolicy: {
      content: string,
    },
  },
};

function Privacy({ data }: { data: QueryData }) {
  const { iubendaDocument: { privacyPolicy } } = data;

  /* eslint-disable react/no-danger */
  return (
    <SecondaryPageLayout>
      <Meta
        title={PrivacyPolicyPageTitle}
        url={Url.PrivacyPolicy}
      />
      <div dangerouslySetInnerHTML={{ __html: privacyPolicy.content }} />
    </SecondaryPageLayout>
  );
  /* eslint-enable react/no-danger */
}

export const query = graphql`
  query PrivacyPageQuery {
    iubendaDocument {
      privacyPolicy {
        content
      }
    }
  }
`;

export default Privacy;
